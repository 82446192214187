body {
  font-family: "Azo Sans 2 Regular", sans-serif;
  text-align: center;
}

.footer-link {
  color: #414141;
  float: right;
  margin: 0px 30px;
}

.footer-link:hover {
  color: rgba(75, 81, 255);
}
.video-wrapper {
  width: 100%;
  height: auto;
  margin: 50 auto;
}

.ant-alert-message {
  color: red;
}

.ant-btn-primary {
  margin-top: 25px;
  padding: 5px 0px;
  background-color: rgba(75, 81, 255);
  border-color: rgba(75, 81, 255);
}

.ant-btn-primary:hover {
  background-color: rgba(75, 81, 255);
  border-color: rgba(75, 81, 255);
}

.ant-btn-primary:focus {
  background-color: rgba(75, 81, 255);
  border-color: rgba(75, 81, 255);
}
.ant-btn-primary:active {
  background-color: rgba(75, 81, 255);
  border-color: rgba(75, 81, 255);
}
.ant-btn-primary[disabled] {
  cursor: pointer;
  background-color: rgb(75, 81, 255, 0.4);
}

.ant-btn-primary[disabled]:hover {
  background-color: rgb(75, 81, 255, 0.4);
}

.ant-radio-button-wrapper:not(:first-child)::before {
  position: absolute;
  top: -1px;
  left: -1px;
  display: block;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  width: 1px;
  height: 0;
  padding: 0;
  background-color: transparent;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: white;
  border-color: rgb(75, 81, 255);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: white;
  background: rgb(75, 81, 255);
  border-color: rgb(75, 81, 255);
}
.ant-slider-with-marks {
  margin-top: 77px;
  margin-bottom: 42px;
}

.ant-slider-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  margin-top: -8px;
  background-color: white;
  border: 3px rgb(75, 81, 255) solid !important;
  transition: none;
}

.ant-slider-handle:hover {
  border: 3px rgb(75, 81, 255) solid;
}
.ant-slider-handle:active {
  border: 3px rgb(75, 81, 255) solid;
}
.ant-slider-handle:focus {
  /* border: 3px rgb(75, 81, 255) solid; */
  box-shadow: none;
}
.ant-slider-track {
  position: absolute;
  height: 4px;
  background-color: rgb(75, 81, 255) !important;
  border-radius: 2px;
}

.ant-slider-track::selection {
  background-color: rgb(75, 81, 255);
}

.ant-slider-track::selection:hover {
  background-color: rgb(75, 81, 255);
}
.ant-slider-dot-active {
  background-color: rgb(75, 81, 255);
  border: rgb(75, 81, 255);
}

.ant-modal-body {
  padding: 25px 25px 5px 25px;
  height: fit-content;
}

.header {
  height: 100px;
  width: 100%;
  background-color: white;
  padding-top: 21px;
  padding-left: 20%;
}

.case-study {
  height: 210px;
  width: 320px;
  display: inline-block;
  background-color: #181547;
  margin: 25px 25px 100px 25px;
  color: white;
  text-align: left;
  font-family: Georgia;
  font-size: 18px;
  box-sizing: content-box;
  padding: 20px;
}

.case-study-header {
  width: 65%;
  font-size: 22px;
  margin: 0;
}
.case-study-link {
  position: relative;
  top: 62px;
  color: rgb(75, 81, 255);
}

.case-study-link:hover {
  color: white;
}

.logo {
  height: 58px;
  width: 170px;
  position: absolute;
  left: 20%;
}
.calculator {
  width: 700px;
  margin: 0 auto;
}

.subtitle {
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  margin-bottom: 75px;
  padding: 0px 20px;
}

.ant-radio-group {
  width: 100%;
  margin: 20px auto;
}

.ant-spin {
  font-size: 18px;
  font-weight: bold;
  line-height: 43px;
  color: rgb(75, 81, 255);
}
.ant-spin-dot-item {
  background-color: rgb(75, 81, 255);
  opacity: 0.8;
}
.copyright {
  float: left;
}

.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow-y: visible;
  background: black;
  opacity: 0.5;
  z-index: 2;
}

.description {
  margin-top: 30px;
  margin-bottom: 0;
  color: #b0aeae;
  text-align: left;
  float: left;
}
.footer-text {
  margin: 70px 0pc;
  color: #b0aeae;
  text-align: center;
}

.ant-divider-horizontal {
  margin: 50px 0px;
}
.results {
  width: 650px;
  margin: 0 auto;
}

.ant-radio-button-wrapper {
  width: 120px;
  margin: 4px;
}

.demo-field {
  float: right;
  width: 300px;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 70px;
  font-family: "Georgia", serif;
}

h2 {
  font-family: "Azo Sans 2 Regular", sans-serif;
  font-size: 18px;
  text-align: left;
  float: left;
  margin-bottom: 30px;
}

h4 {
  font-size: 30px;
  font-weight: bold;
  margin: 0;
}
h5 {
  font-family: "Azo Sans 2 Regular", sans-serif;
  font-style: normal;
  font-size: 18px;
  color: rgb(75, 81, 255);
  text-align: right;
  float: right;
}

.results-text {
  color: #b0aeae;
  text-align: center;
  width: 75%;
  margin: 0 auto;
  display: block;
}

.results-box-lg {
  width: 600px;
  height: fit-content;
  margin: 90px auto;
  background: #4b51ff;
  padding: 22px 10px;
}

.results-box-sm {
  display: inline-flex;
  width: 268px;
  height: 42px;
  background: rgba(157, 157, 158, 0.11);
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  justify-content: center;
  padding: 4px 0px;
}
.footer {
  height: 50px;
  margin-top: 200px;
  padding: 12px 10%;
  color: #414141;
  border-top: 0.7px #b0aeae solid;
}

.headerImage {
  background: url("https://images.unsplash.com/photo-1566654032194-09ec2c9c5d2f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1490&q=80");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 412px;
  background-position-y: 75%;
}

h3 {
  font-size: 20px;
  display: inline-block;
}

.container-sm {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0px 80px;
  margin: 60px 0px;
}

@media only screen and (max-width: 850px) {
  .footer-link {
    float: none !important;    padding: 0px 10px;    padding: 0px 10px;    padding: 0px 10px;
    text-align: center !important;
    margin: 0 auto;
  }
  .copyright {
    float: none;
  }
}

@media only screen and (max-width: 600px) {
  body {
    text-align: center;
    max-width: 100%;
  }
  .calculator {
    max-width: 100%;
  }
  h1 {
    font-size: 30px;
    margin: 50px 0px;
  }
  h2 {
    padding: 0px;
    text-align: center;
    float: none;
    width: 100%;
    margin-bottom: 19px;
  }
  h3 {
    font-size: 18px;
    text-align: left;
    width: 50%;
  }
  h4 {
    font-size: 27px;
    line-height: normal;
    font-weight: bold;
  }
  h5 {
    float: none;
    text-align: center;
    width: 100%;
    font-size: 22px;
  }
  .headerImage {
    background: url("https://images.unsplash.com/photo-1566654032194-09ec2c9c5d2f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1490&q=80");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 112px;
    background-position-y: -70px;
  }
  .header {
    padding: 25px 0px;
    display: flex;
    justify-content: center;
  }
  .logo {
    left: 0;
    position: unset;
  }

  .calculator {
    width: 100%;
    padding: 20px 10%;
  }

  .case-study {
    height: 220px;    padding: 0px 10px;    padding: 0px 10px;    padding: 0px 10px;
    width: 230px;    padding: 0px 10px;
    margin: 25px;
  }
  .case-study-header {
    width: 90%;
  }

  .case-study-link {
    position: relative;
    top: 30px;
  }

  .ant-slider-with-marks {
    margin-bottom: 30px;
    margin-top: 0px;
  }

  .results-subtext {
    font-size: 16px;
  }
  .results {
    width: 90%;
    margin: 0 auto;
  }
  .results-box-lg {
    width: 100%;
    height: fit-content;
    margin: 0 auto;
    background: #4b51ff;
    padding: 25px 5px;
    margin-top: 33px;
  }
  .results-box-sm {
    width: 150px;
    font-size: 22px;
    padding-top: 4px;
  }
  .video-wrapper {
    width: 100%;
    height: 200px;
  }
  .ant-form {
    margin-top: 25px;
  }
  .ant-radio-button-wrapper {
    width: 80%;
    margin: 10px 0px;
  }
  .ant-modal-body {
    padding: 15px 10px;
  }

  .demo-field {
    float: none;
    width: 100%;
  }
  .container-sm {
    padding: 0;
    display: block;
  }

  .description {
    text-align: center;
  }
  .subtitle {
    padding: 0px 10px;
    font-size: 18px;
    margin-bottom: 39px;
  }
  .ant-slider-handle {
    height: 17px;
    width: 17px;
    margin-top: -6px;
  }
  .footer {
    height: 50px;
    margin-top: 75px;
    padding: 5px 25px;
    color: #414141;
    text-align: center;
  }
  
}

@media only screen and (max-width: 375px) {
  .calculator {
    padding: 20px;
  }
  .container-sm {
    padding: 0;
    display: block;
  }
  h4 {
    font-size: 22px;
  }
  .results-text {
    font-size: 12px;
    margin-top: 10px;
  }
  .subtitle {
    font-size: 15px;
  }
  h1 {
    margin: 40px 0px;
  }
  .video-wrapper {
    width: 100%;
    height: 150px;
  }
  .footer {
    padding: 0px 10px;
    text-align: center;
  }
  .footer-link {
    margin: 0;
  }
}
